<template>
  <div class="card">
    <div class="name">
      <div class="cardIcon">
        <img :src="cardInfo.imageUrl" alt="" />
      </div>
      <p>{{ cardInfo.name }}</p>
    </div>
    <p class="cardCode" v-if="cardInfo.cardNumber || cardInfo.cardNo">
      **** **** ****
      {{ cardInfo.cardNumber? cardInfo.cardNumber.slice(12) : cardInfo.cardNo.slice(12)}}
    </p>
  </div>
</template>
<script>
export default {
  name: "Card",
  props: {
    cardInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  created() {
  },
};
</script>
<style scoped>
.card {
  width: 100%;
  background: #ffffff;
  border-radius: 0.12rem;
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.2rem;
  color: #030f14;
  padding: 0.16rem;
  box-sizing: border-box;
  margin-top: 0.12rem;
}
.name {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cardIcon {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.cardIcon img {
  height: 0.24rem;
  object-fit: cover;
}
.cardIcon img + img {
  margin-left: 0.08rem;
}
.cardCode {
  text-align: left;
  margin-top: 0.12rem;
}
</style>