<template>
  <div class="cardBox">
    <topbar
      :title="myCard"
      bgColor="#fff"
      :right="del ? new_FKFS_wanCheng : new_FKFS_gengGai"
      :rightType="2"
      category="payment"
      @rightClick="rightClick"
      backPage="/mine"
    ></topbar>
    <!-- <p class="title">{{ new_FKFS_guanLi }}</p> -->
    <div class="cardList">
      <div
        class="itemBox"
        :ref="'item_' + index"
        v-for="(item, index) in cardList"
        :key="item.token"
      >
        <p class="del" v-show="del" @click="delPlace(index)"></p>
        <card :cardInfo="item"></card>
      </div>
      <div
        class="btnBox"
        :class="{ active: !cardList.length }"
        @click="changePage('bindCard')"
        v-show="!del"
      >
        <p v-if="!cardList.length">{{ notSavedCard }}</p>

        <div class="addBtn">
          <span> {{ add_new_card }} </span>
        </div>
      </div>
    </div>
    <div class="addBox" v-show="shoAddBox">
      <bind-card-con
        class="bindBox"
        :show="true"
        :saveC="true"
        :btnText="$store.state.language == 1 ? '確認' : 'Confirm'"
        @closeBox="closeAddBox"
        @refreshCard="getCard"
      >
        <template v-slot:addTitle>
          <div class="add-box-title">
            <i @click="closeAddBox"></i>
            <span>{{ add_new_card }}</span>
          </div>
        </template>
      </bind-card-con>
    </div>
    <confirm-box
      v-show="showAddErr"
      @finish="finish"
      :title="XYK_del_one"
      :subTitle="XYK_del_Two"
      :subTitleShow="true"
      :size="16"
      :finishBtn="$store.state.language == 1 ? '確認' : 'Confirm'"
    ></confirm-box>
  </div>
</template>
<script>
import Topbar from "../../components/Topbar.vue";
import Card from "../../components/mine/Card.vue";
import BindCardCon from "../../components/mine/BindCardCon.vue";
import ConfirmBox from "../../components/common/CnfirmBox.vue";

import { postPaas } from "../../assets/utils/request";
import language from "../../assets/js/language";
export default {
  name: "CreditCard",
  data() {
    return {
      cardList: [],
      shoAddBox: false,
      showAddErr: false,
      del: false,
      right: "更改",
      lanType: this.$store.state.language,
      notSavedCard: "",
      addCard: "",
      myCard: "",
      pageLink: "",
      new_FKFS_guanLi: "",
      new_FKFS_gengGai: "",
      new_FKFS_wanCheng: "",
      add_new_card: "",
      XYK_del_one: "",
      XYK_del_Two: "",
      HQXXSB:""
    };
  },
  components: {
    Topbar,
    Card,
    BindCardCon,
    ConfirmBox,
  },
  methods: {
    rightClick() {
      if (!this.cardList.length) {
        return;
      }
      this.del = !this.del;
      this.right = this.del ? "完成" : "更改";
    },
    async delPlace(index) {
      var params = {
        actionName: "candao.member.creditCardDelete",
        content: {
          token: this.cardList[index].token,
          userId: this.$store.state.userInfo.userId,
        },
      };
      let result = await postPaas("UserUnify", params);
      if (result.status == 1) {
        this.$refs["item_" + index].remove();
      }else{
        this.$toast(this.HQXXSB)
      }
    },
    finish() {
      this.showAddErr = false;
    },
    changePage() {
      window.dataLayer.push({
        event: "new_payment",
        ecommerce: {
          Payment_count: this.cardList.length,
        },
      });
      if (this.cardList.length >= 3) {
        this.showAddErr = true;
        return;
      }
      this.shoAddBox = true;
      // 5123450000000008
      // 2223000000000007
      // 4508750015741019
    },
    closeAddBox() {
      this.shoAddBox = false;
    },
    // 获取信用卡
    async getCard() {
      var params = {
        actionName: "candao.member.getReditCardList",
        content: {},
      };
      let result = await postPaas("UserUnify", params);
      if (result.status == 1) {
        this.cardList = result.data.data;
      }
    },
    setLanguage() {
      var type = "chinese";
      if (this.lanType != 1) {
        type = "english";
      }
      this.notSavedCard = language.notSavedCard[type];
      this.addCard = language.addCard[type];
      this.myCard = language.myCard[type];
      this.new_FKFS_guanLi = language.new_FKFS_guanLi[type];
      this.new_FKFS_gengGai = language.new_FKFS_gengGai[type];
      this.new_FKFS_wanCheng = language.new_FKFS_wanCheng[type];
      this.add_new_card = language.add_new_card[type];
      this.XYK_del_one = language.XYK_del_one[type];
      this.XYK_del_Two = language.XYK_del_Two[type];
      this.HQXXSB=language.HQXXSB[type]
    },
  },
  created() {
    this.setLanguage();
    this.getCard();
    var href = window.location.href.split("/");
    this.pageLink = href[0] + "//";
    for (var i = 1; i < href.length; i++) {
      this.pageLink += href[i];
      if (href[i]) {
        break;
      }
    }
  },
};
</script>
<style scoped>
.cardBox {
  height: 100vh;
  background-color: #f2f3f5;
}
.cardList {
  padding: 0.16rem;
  padding-top: 0;
  box-sizing: border-box;
}
.addBtn {
  width: 3.43rem;
  height: 0.44rem;
  border: 0.01rem solid #e4022b;
  border-radius: 0.12rem;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.46rem;
  color: #fff;
  background-color: #e4022b;
  margin: auto;
  position: fixed;
  bottom: 0.34rem;
}
.btnBox.active {
  width: 3.4rem;
  border-radius: 0.04rem;
  margin-top: 0.24rem;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #e4022b;
  height: 1rem;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 0.16rem 0;
}
.del {
  width: 0.3rem;
  height: 0.24rem;
  background: url("../../assets/icon/del.png") no-repeat center;
  background-size: 0.24rem 0.24rem;
  margin-right: 0.16rem;
}
.itemBox {
  display: flex;
  align-items: center;
}
.title {
  font-family: PingFang HK;
  font-style: normal;
  font-weight: 600;
  font-size: 0.16rem;
  color: #030f14;
  margin-top: 0.16rem;
  padding: 0 0.16rem;
  box-sizing: border-box;
  text-align: left;
}
.addBox {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.bindBox {
  width: 100%;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  padding-bottom: 0.2rem;
}
.add-box-title {
  width: 100%;
  height: 0.54rem;
  background-color: #f2f3f5;
  line-height: 0.54rem;
  position: relative;
  font-family: PingFang TC;
  font-style: normal;
  font-weight: 600;
  font-size: 0.16rem;
  color: #051b24;
}
.add-box-title i {
  width: 0.24rem;
  height: 0.24rem;
  background: url("../../assets/icon/login_close.png") no-repeat center;
  background-size: cover;
  position: absolute;
  left: 0.16rem;
  top: 50%;
  transform: translateY(-50%);
}
</style>